import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deTranslation from './de/translation.json';
import enTranslation from './en/translation.json';
import esTranslation from './es/translation.json';
import frTranslation from './fr/translation.json';
import itTranslation from './it/translation.json';
import nlTranslation from './nl/translation.json';
import plTranslation from './pl/translation.json';

export const resources = {
    fallback: {
        translation: enTranslation,
    },
    de: {
        translation: deTranslation,
    },
    en: {
        translation: enTranslation,
    },
    es: {
        translation: esTranslation,
    },
    fr: {
        translation: frTranslation,
    },
    it: {
        translation: itTranslation,
    },
    nl: {
        translation: nlTranslation,
    },
    pl: {
        translation: plTranslation,
    },
};

let fallbackLng = [];
if (navigator?.language) {
    fallbackLng.push(navigator.language);
}
fallbackLng.push('fallback');

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLng,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
    });

export default i18n;