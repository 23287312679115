export const productGroupNames = {
    windows: "windows",
    office: "office",
    unspecified: "unspecified"
};

export const unspecifiedProductNameSuffix = "Unspecified";

export const productTypeNames = {
    windows: "windows",
    mac: "mac"
}