import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ProductInfo, ValidateIIDResponse, getShortUrlInfo, useApiRequest, enabledCallbackPage, getFeatureOptions } from "../data";
import { useNavigate, useParams } from "react-router-dom";
import i18n from 'i18next';


export interface AppState {
    urlId: string,
    phoneNumber: string,
    selectedProduct?: ProductInfo,
    validationResponse?: ValidateIIDResponse,
    callbackEstimatedWaitMinutes?: number,
    callbackPageEnabled: boolean,
    hasFeedbackSubmitted: boolean,
    selfHelpActivated: boolean,
    hasMacInstallationCountPassedToContact: boolean,
    checksumCount: number,
    activationAttempts: number,
    errorType?: string,
    appInsightConnectionString: string,
    hasHandledCallbackServerError: boolean,
    callbackErrorReached: boolean
    featureOptions: { [key: string]: boolean };
}

export interface AppActions {
    navigateTo: (target: string | number) => void,
    reloadUrlInfo: (recordUrlHit?: boolean) => Promise<void>,
    setPhoneNumber: (string) => void,
    setSelectedProduct: (ProductInfo) => void,
    enableCallbackPage: () => void,
    setCallbackEstimatedWaitMinutes: (h: number) => void,
    setHasFeedbackSubmitted: (h: boolean) => void,
    setSelfHelpActivated: (h: boolean) => void,
    setHasMacInstallationCountPassedToContact: (h: boolean) => void,
    setValidationResponse: (ValidateIIDResponse) => void,
    setChecksumCount: (number) => void,
    setActivationAttempts: (number) => void,
    setErrorType: (string) => void,
    setHasHandledCallbackServerError: (boolean) => void,
    setCallbackErrorReached: (boolean) => void
}

export const AppStateContext = createContext<AppState>(undefined);
export const AppActionsContext = createContext<AppActions>(undefined);

export const WithAppState = ({ children }: { children?: React.ReactNode }) => {
    const { urlId } = useParams();
    const navigate = useNavigate();
    const [appInsightConnectionString, setAppInsightConnectionString] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [callbackEstimatedWaitMinutes, setCallbackEstimatedWaitMinutes] = useState<number | undefined>(undefined);
    const [callbackPageEnabled, setCallbackPageEnabled] = useState<boolean>(false);
    const [selfHelpActivated, setSelfHelpActivated] = useState<boolean>(false);
    const [hasFeedbackSubmitted, setHasFeedbackSubmitted] = useState<boolean>(false);
    const [hasMacInstallationCountPassedToContact, setHasMacInstallationCountPassedToContact] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductInfo | undefined>(undefined);
    const [checksumCount, setChecksumCount] = useState<number>(0);
    const [activationAttempts, setActivationAttempts] = useState<number>(0);
    const [errorType, setErrorType] = useState<string | undefined>(undefined);
    const [validationResponse, setValidationResponse] = useState<ValidateIIDResponse | undefined>(undefined);
    const [hasHandledCallbackServerError, setHasHandledCallbackServerError] = useState<boolean>(false);
    const [callbackErrorReached, setCallbackErrorReached] = useState<boolean>(false);
    const getShortUrlInfoAPI = useApiRequest(getShortUrlInfo, true);
    const [featureOptions, setFeatureOptions] = useState<{ [key: string]: boolean; }>({});

    const fetchFeatureOptions = async () => {
        if (Object.keys(featureOptions).length === 0) {
            try {
                const data = await getFeatureOptions(urlId);
                setFeatureOptions(data);
            } catch (error) {
                setFeatureOptions({ "UseOCCPhone": false, "isCaptchaEnabled": false });
            }
        }
    };
      
    // Aparrently this enables callback
    const enableCallbackPage = useCallback(() => {
        enabledCallbackPage(urlId);
        setCallbackPageEnabled(true);
    }, [navigate, urlId]);

    const navigateTo = useCallback((path: string) => {
        if (typeof path === "string" && path.startsWith("/contact")) {
            enableCallbackPage();
        }
        if (path[0] === "/") {
            navigate(`/${urlId}${path}`)
        } else {
            navigate(path);
        }
    }, [navigate, urlId]);

    const reloadUrlInfo = useCallback(async (recordUrlHit: boolean = false, skipStateHydrate: boolean = false) => {
        await fetchFeatureOptions();
        await getShortUrlInfoAPI.call(urlId, recordUrlHit).then((response) => {
            if (response.status === 404) {
                navigateTo("/error/invalidUrl");
            } else if (response.status === 409) {
                navigateTo("/error/expired");
            } else {
                const data = response.data.experienceContent;
                setPhoneNumber(data.phoneNumber);
                if (!appInsightConnectionString) {
                    setAppInsightConnectionString(atob(response.headers["x-telemetry-key"]));
                }
                if (skipStateHydrate) {
                    return;
                }
                setChecksumCount(data.checksumCount);
                setActivationAttempts(data.activationAttempts);
                setHasHandledCallbackServerError(data.hasHandledCallbackServerError);
                if (data.productName !== null) {
                    setSelectedProduct({
                        productGroup: data.productGroup,
                        productType: data.productType,
                        productName: data.productName,
                        numberOfDigits: data.numberOfDigits,
                        productLaunchOrder: 0
                    });
                }
                if (data.validationResponse !== null) {
                    setValidationResponse(JSON.parse(data.validationResponse));
                }
                if (data.callbackEstimatedWaitMinutes !== null) {
                    setCallbackEstimatedWaitMinutes(data.callbackEstimatedWaitMinutes);
                }
                if (data.hasFeedbackSubmitted) {
                    setHasFeedbackSubmitted(true);
                }
                if (data.errorType) {
                    setErrorType(data.errorType);
                }
                if (data.callbackPageEnabled) {
                    setCallbackPageEnabled(true);
                }
                if (data.selfHelpActivated) {
                    setSelfHelpActivated(true);
                }
                if (data.callbackErrorReached) {
                    setCallbackErrorReached(true);
                }
                if (data.language && i18n.resolvedLanguage === 'fallback') {
                    i18n.changeLanguage(data.language);
                }
            }
        })
    }, [getShortUrlInfoAPI, urlId, navigateTo, appInsightConnectionString]);

    const appState = {
        urlId,
        phoneNumber,
        selectedProduct,
        validationResponse,
        hasMacInstallationCountPassedToContact,
        callbackEstimatedWaitMinutes,
        callbackPageEnabled,
        selfHelpActivated,
        hasFeedbackSubmitted,
        checksumCount,
        activationAttempts,
        errorType,
        appInsightConnectionString,
        hasHandledCallbackServerError,
        callbackErrorReached,
        featureOptions
    };

    const appActions = {
        setSelectedProduct,
        reloadUrlInfo,
        setPhoneNumber,
        navigateTo,
        setValidationResponse,
        enableCallbackPage,
        setSelfHelpActivated,
        setHasMacInstallationCountPassedToContact,
        setCallbackEstimatedWaitMinutes,
        setHasFeedbackSubmitted,
        setChecksumCount,
        setErrorType,
        setHasHandledCallbackServerError,
        setActivationAttempts,
        setCallbackErrorReached
    };

    return (
        <AppStateContext.Provider value={appState}>
            <AppActionsContext.Provider value={appActions}>
                {children}
            </AppActionsContext.Provider>
        </AppStateContext.Provider>
    );
}

export const useAppState = (): AppState => {
    return useContext(AppStateContext);
}
export const useAppActions = (): AppActions => {
    return useContext(AppActionsContext);
}