import { ImageFit, Stack, PrimaryButton, Text, Image, TextField, Button } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppActions, useAppState } from '@src/states';
import { useApiRequest, validateIID, onError } from '@src/data';
import { NavigateWithUrlId, SubmitButton } from '@src/components';
import computersIcon from '@src/resources/computers.svg';
import { ProductActivationHeader } from './productActivationHeader';


export const ProductActivationManualOverrideFormView = () => {
    const { t } = useTranslation();
    const { urlId, validationResponse, selectedProduct, hasMacInstallationCountPassedToContact } = useAppState();
    const { setValidationResponse, navigateTo, reloadUrlInfo, setHasMacInstallationCountPassedToContact } = useAppActions();
    const [installedDevices, setInstalledDevices] = useState<string>("");
    const [isInConfirmation, setIsInConfirmation] = useState<boolean>(false);
    const validateIIDApi = useApiRequest(validateIID, true);

    const onChange = useCallback((i, value) => {
        if (value === "") {
            setInstalledDevices("");
            return;
        }
        const n = parseInt(value);
        if (!Number.isNaN(n)) {
            setInstalledDevices(n.toString());
        }
    }, [setInstalledDevices]);

    const onSubmitClicked = useCallback(() => {
        setIsInConfirmation(true);
    }, [setIsInConfirmation]);
    const onBackClicked = useCallback(() => {
        setIsInConfirmation(false);
    }, [setIsInConfirmation]);
    const onFormSubmit = useCallback(() => {
        const installed = parseInt(installedDevices);
        if (installed > validationResponse.maxInstallCount) {
            onError(urlId, "exceededActivation");
            navigateTo("/error/exceededActivation");
            return;
        } else if (selectedProduct.productType.toLowerCase() === "mac") {
            if (validationResponse.isReasonCodeTransferEnabled) {
                setHasMacInstallationCountPassedToContact(true);
                navigateTo("/contact");
                return;
            } else {
                onError(urlId, "exceededActivation");
                navigateTo("/error/exceededActivation");
                return;
            }
        }
        validateIIDApi.call(urlId, {
            "IID": validationResponse.iid,
            "installedDevices": installed,
            "overrideStatusCode": validationResponse.overrideStatusCode
        }).then(() => reloadUrlInfo());
    }, [installedDevices, validationResponse.maxInstallCount, validationResponse.iid, validationResponse.overrideStatusCode, validationResponse.isReasonCodeTransferEnabled, selectedProduct.productType, validateIIDApi, urlId, navigateTo, setHasMacInstallationCountPassedToContact, reloadUrlInfo]);

    useEffect(() => {
        if (validateIIDApi.data !== null) {
            setValidationResponse(validateIIDApi.data);
        }
    }, [validateIIDApi.data]);

    if (validationResponse === undefined) {
        return <NavigateWithUrlId to="/activate" />;
    } else if (validationResponse.activationSuccessful) {
        return <NavigateWithUrlId to="/activate/result" />;
    }

    if (hasMacInstallationCountPassedToContact) {
        return <NavigateWithUrlId to="/contact" />;
    }

    const installedDevicesNum = parseInt(installedDevices);

    const imageStyles = {
        root: {
            marginBottom: 40,
            height: 153,
        },
        image: {
            height: 153,
        }
    };
    const inputStyles = {
        root: {
            marginBottom: 30
        },
        fieldGroup: {
            height: 48,
        },
        field: {
            fontSize: 17,
        }
    };
    const buttonStyles = {
        root: {
            marginBottom: 12,
            height: 52,
            borderRadius: 8,
        }
    };

    return (
        <Stack>
            <ProductActivationHeader>
                {t("productActivation.title", "Microsoft Product Activation")}
            </ProductActivationHeader>
            <div style={{ marginBottom: 45 }} />
            <Image src={computersIcon} imageFit={ImageFit.center} styles={imageStyles} alt="Computers Icon." />

            {
                isInConfirmation ?
                    <>
                        <Text variant="large">
                            <Trans i18nKey="productActivation.manualOverrideConfirmMessage" count={installedDevicesNum}>
                                You have installed <b>{{ count: installedDevices }}</b> computer with this copy of the software.
                            </Trans>
                        </Text>
                        <div style={{ marginBottom: 24 }} />
                        <SubmitButton
                            text={t("common.next", "Next")}
                            onSubmit={onFormSubmit}
                            isLoading={validateIIDApi.isLoading}
                        />
                        <Button
                            text={t("common.back", "Back")}
                            onClick={onBackClicked}
                            allowDisabledFocus
                            disabled={validateIIDApi.isLoading}
                            styles={buttonStyles}
                        />
                    </>
                    :
                    <>
                        <Text variant="large">
                            {t("productActivationManualOverride.question", "How many computers have you installed, with this copy of the software?")}
                        </Text>
                        <div style={{ marginBottom: 16 }} />
                        <TextField
                            value={installedDevices}
                            placeholder={t("productActivationManualOverride.inputLabel", "Enter number of computers")}
                            onChange={onChange}
                            disabled={validateIIDApi.isLoading}
                            styles={inputStyles}
                        />
                        <PrimaryButton
                            text={validateIIDApi.isLoading ? "" : t("common.formSubmit", "Submit")}
                            onClick={onSubmitClicked}
                            allowDisabledFocus
                            disabled={installedDevices === ""}
                            styles={buttonStyles}
                        />
                    </>
            }
        </Stack>
    );
}