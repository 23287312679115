import { ImageFit, Stack, PrimaryButton, Text, Image, Button } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { useAppState } from 'states';

import { unspecifiedProductNameSuffix, productGroupNames } from '@src/constants';
import windowsIcon from '@src/resources/windows.svg';
import generalMSIcon from '@src/resources/ms.svg';
import errorIcon from '@src/resources/error.svg';
import { ProductInfo } from '@src/data';


const getProductDisplayName = (product: ProductInfo): string => {
    // TODO: Product Name translation
    if (!product.productName.endsWith(unspecifiedProductNameSuffix)) {
        return product.productName;
    } else if (product.productGroup !== productGroupNames.unspecified) {
        const group = product.productGroup;
        return group.charAt(0).toUpperCase() + group.slice(1);
    } else {
        return "Microsoft Product";
    }
}

interface ProductActivationHeaderProps {
    hasError?: boolean,
    children?: React.ReactNode
}

export const ProductActivationHeader = ({ hasError = false, children }: ProductActivationHeaderProps) => {
    const { selectedProduct } = useAppState();
    const { t } = useTranslation();
    if (selectedProduct === undefined) {
        return null;
    }

    let icon;
    if (hasError) {
        icon = errorIcon;
    } else if (selectedProduct.productGroup.toLowerCase() === productGroupNames.windows) {
        icon = windowsIcon;
    } else {
        icon = generalMSIcon;
    }

    const containerStyles = {
        root: {
            marginLeft: -2,
            marginRight: -2,
            marginTop: 10,
            marginBottom: 28,
        }
    }

    const imageStyles = {
        root: {
            height: 58,
            width: 58,
            marginTop: 3,
            marginRight: 10,
        },
        image: {
            height: 58,
        }
    };

    const productNameStyles = {
        root: {
            fontWeight: "600",
            fontSize: 20,
            margin: 0
        }
    };

    const detailTextStyles = {
        root: {
            fontWeight: "400",
            fontSize: 13,
            width: 210,
            color: "#6e6e6e",
            margin: 0
        }
    };

    const errorTextStyles = {
        root: {
            fontWeight: "700",
            fontSize: 14,
            color: "#b44e28"
        }
    };

    return (
        <Stack horizontal styles={containerStyles}>
            <Stack.Item grow={0}>
                <Image src={icon} imageFit={ImageFit.centerContain} styles={imageStyles} alt="Product Logo." />
            </Stack.Item>
            <Stack>
                <Text as="h1" styles={productNameStyles}>
                    {getProductDisplayName(selectedProduct)}
                </Text>
                <Text as="h2" styles={detailTextStyles}>
                    {
                        hasError ?
                            <Text styles={errorTextStyles}>
                                {t("productActicationHeader.somethingWrongError", "Something's Wrong")}
                            </Text>
                            :
                            children
                    }
                </Text>
            </Stack>
        </Stack>
    );
}