import { Stack, Text, ImageFit, Image } from '@fluentui/react';
import { FC, ReactChildren, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import i18next from '@src/i18n';

import barricadeSvg from '@src/resources/barricade.svg'
import { useAppActions, useAppState } from "@src/states";


interface ErrorContentProps {
    title?: string,
    body?: string | ReactChildren
}

const ErrorContent: FC<ErrorContentProps> = ({ title, body }) => {
    const headerStyles = {
        root: {
            fontSize: 22,
            fontWeight: "600",
            textAlign: "center",
            marginBottom: 31,
            display: "block"
        }
    };
    const bodyStyles = {
        root: {
            fontSize: 16,
            fontWeight: "400",
            display: "inline-block"
        }
    };
    return (
        <Stack.Item grow={1}>
            {
                title &&
                <Text styles={headerStyles}>
                    {title}
                </Text>
            }
            <Text styles={bodyStyles}>
                {body}
            </Text>
        </Stack.Item>
    );
}

// TODO: Dynamic get this on rendering
const errorTypeContent = {
    "expired": {
        title: i18next.t("error.expired.title", "Link Expired!"),
        body: i18next.t("error.expired.body", "We’re sorry, but the link you are trying to reach has expired. Please call back to request a new link.")
    },
    "invalidUrl": {
        title: i18next.t("error.invalidUrl.title", "Invalid Link!"),
        body: i18next.t("error.invalidUrl.body", "We’re sorry, but the link you are trying to reach is unable to be validated at this time. Please call back to request a new link.")
    },
    "noFurtherSupport": {
        title: i18next.t("error.noFurtherSupport.title", "End of Support"),
        body: (
            <Trans i18nKey="error.noFurtherSupport.body">
                <div>
                    <p>
                        Assisted Support is no longer available for this product.  Other support resources may be available to you at <a href="http://support.microsoft.com" target="_blank">http://support.microsoft.com</a>
                    </p>
                    <p>
                        To learn about the latest Microsoft products, visit <a href="http://store.microsoft.com/ " target="_blank">http://store.microsoft.com/</a>
                    </p>
                </div>
            </Trans>
        )
    },
    "exceededActivation": {
        title: i18next.t("error.exceededActivation.title", "License Use Exceeded"),
        body: i18next.t("error.exceededActivation.body", "Unfortunately, we cannot activate your product as you have exceeded the number of devices this license can be installed on, per the End User License Agreement. You may need to purchase an additional software license, or return to your reseller to purchase a new software package.")
    },
};

const aboveMaxInstallCountErrorCodes = new Set([
    "45092",
    "45152",
    "45131",
    "45153",
    "3007",
    "3008",
]);

const activationServerErrorCodes = new Set([
    "45090",
    "45112",
    "3005",
    "3010",
    "45269",
    "45270",
]);

const altActivationServerErrorCodes = new Set([
    "45116",
    "45147",
    "45149",
]);

const getActivationErrorCodeContent = (errorCode: string) => {
    if (errorCode === "45268") {
        return {
            body: i18next.t("error.45268.body", "We’re sorry, we cannot complete this transaction. The product key entered does not match our records. \nPlease contact your computer manufacturer for assistance, OR, wait 24 to 48 hours to re-attempt activation of your software.")
        };
    } else if (errorCode === "45267") {
        return {
            body: i18next.t("error.45267.body", "We’re sorry, we cannot complete this transaction. The product key entered does not match our records. Please contact your computer manufacturer for assistance.")
        };
    } else if (errorCode === "45057") {
        return {
            body: i18next.t("error.45057.body", "Unfortunately, your Installation ID was unable to be validated at this time. Please ensure you have an active internet connection and try again.")
        };
    } else if (aboveMaxInstallCountErrorCodes.has(errorCode)) {
        return errorTypeContent["exceededActivation"];
    } else if (activationServerErrorCodes.has(errorCode)) {
        return {
            body: (
                <Trans i18nKey="error.activationServerError.body">
                    Unfortunately, we cannot complete the transaction as our records do not recognize this as a valid product.<br />
                    <br />
                    You may be able to return the software to your reseller. To learn about licensing rights for your Microsoft product, visit <a href="https://microsoft.com/howtotell" target="_blank">microsoft.com/howtotell</a>.<br />
                    <br />
                    Alternatively, you may consider purchasing more current version of your software at <a href="https://store.microsoft.com" target="_blank">store.microsoft.com</a>, should your device meets the minimal requirements.
                </Trans>
            )
        };
    } else if (altActivationServerErrorCodes.has(errorCode)) {
        return {
            body: (
                <Trans i18nKey="error.altActivationServerError.body">
                    Unfortunately, we cannot complete the transaction as our records do not recognize this as a valid product.<br/>
                    <br />
                    If the software came pre-installed, locate your product key on the Certificate of Authenticity.  Then, use the Change Product Key option in the Activation Window to complete the activaiton.  If you purchased retail software, go back to your reseller. You may be able to return the software to your reseller for a new product.
                </Trans>
            )
        };
    }
    
    return {
        body: i18next.t("error.general.body", "We’re sorry, your Installation ID was unable to be validated.")
    };
};

export const ErrorPage = () => {
    const { t } = useTranslation();
    const { errorType } = useParams();
    const { validationResponse } = useAppState();
    const { setErrorType } = useAppActions();

    useEffect(() => setErrorType(errorType), [errorType, setErrorType]);

    const containerStyles = {
        root: {
            paddingLeft: 16,
            paddingRight: 16,
            alignItems: "center",
            minHeight: "100%",
            paddingBottom: 25
        }
    };
    const headerStyles = {
        root: {
            fontSize: 22,
            fontWeight: "600",
            textAlign: "center",
            marginTop: 34,
            marginBottom: 45,
            display: "block"
        }
    };
    const imageStyles = {
        root: {
            height: 156,
            width: "100%",
            marginBottom: 47
        },
        image: {
            height: 156,
        }
    };
    const closePromptStyles = {
        root: {
            fontSize: 14,
            fontWeight: "400",
            marginTop: 25
        }
    };

    let content = errorTypeContent[errorType];
    if (errorType === "activationError") {
        content = getActivationErrorCodeContent(validationResponse.reasonCode);
    }

    return (
        <Stack styles={containerStyles}>
            <Text styles={headerStyles}>
                {t("errorPage.title", "Microsoft Product Activation")}
            </Text>
            <Image src={barricadeSvg} imageFit={ImageFit.center} alt="Error." styles={imageStyles} />
            <ErrorContent {...content} />
            <Text as="div" styles={closePromptStyles}>
                {t("errorPage.closeWindow", "You may close this window now")}
            </Text>
            <div style={{ height: 25 }} />
        </Stack>
    );
}