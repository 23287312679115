import { IconButton, PrimaryButton, Spinner, Stack, Text } from "@fluentui/react";


interface SubmitButtonProps {
    text: string,
    onSubmit: any,
    isLoading: boolean,
    disabled?: boolean
}


export const SubmitButton = ({ text, onSubmit, isLoading, disabled = false }: SubmitButtonProps) => {
    const submitButtonStyles = {
        root: {
            marginBottom: 12,
            height: 52,
            borderRadius: 8,
        }
    };

    const spinnerContainerStyles = {
        root: {
            position: 'absolute' as 'absolute',
            width: "100%",
            paddingTop: 16
        }
    };

    return <Stack style={{ position: 'relative' }}>
        <PrimaryButton
            text={isLoading ? "" : text}
            onClick={onSubmit}
            allowDisabledFocus
            disabled={isLoading || disabled}
            styles={submitButtonStyles}
        />
        {
            isLoading &&
            <Stack styles={spinnerContainerStyles}>
                <Spinner />
            </Stack>
        }
    </Stack>;
}