import { Icon, IconButton, Modal, Stack, Text } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';


interface HintButtonProps {
    title: string,
    children?: React.ReactNode
}


export const HintButton = ({ title, children }: HintButtonProps) => {
    const [isHintOpen, { setTrue: showHint, setFalse: hideHint }] = useBoolean(false);

    const iconStyles = {
        root: {
            marginLeft: 4,
            marginRight: 4,
            color: "#0078D4",
            fontSize: 20,
            position: "relative" as "relative",
            top: 5,
            cursor: "pointer",
        }
    };
    const hintContainerStyles = {
        main: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            marginLeft: 10,
            marginRight: 10,
            maxWidth: 390,
            minHeight: 100,
        },
        scrollableContent: {
            overflow: "hidden" as "hidden",
        }
    };
    const headerStyles = {
        root: {
            fontSize: 20,
            fontWeight: "600"
        }
    };
    const contentStyles = {
        root: {
            fontSize: 16,
            fontWeight: "400"
        }
    };
    const dismissButtonStyles = {
        root: {
            color: "#323130",
            marginTop: 2,
            marginRight: -10
        },
        rootHovered: {
            color: "#1A1918",
            background: "none"
        },
    };

    return <>
        <Icon iconName="info" styles={iconStyles} onClick={showHint} aria-hidden={false} tabIndex={0} onKeyUp={function (e) {
            if (e.key === "Enter") {
                (document.activeElement as HTMLElement).click();
            }
        }} />
        <Modal
            isOpen={isHintOpen}
            onDismiss={hideHint}
            isDarkOverlay={false}
            styles={hintContainerStyles}
        >
            <Stack horizontal verticalAlign="center">
                <Stack.Item grow={1}>
                    <Text styles={headerStyles}>
                        {title}
                    </Text>
                </Stack.Item>
                <IconButton
                    styles={dismissButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={hideHint}
                />
            </Stack>
            <Text styles={contentStyles}>
                {children}
            </Text>
        </Modal>
    </>;
}