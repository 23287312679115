import { ImageFit, Stack, PrimaryButton, Text, Image, Button } from '@fluentui/react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppActions } from '@src/states';
import lockAndPhoneIcon from '@src/resources/lock_and_phone.svg';

import { ProductActivationHeader } from './productActivationHeader';


export const ActivationResultConfirmationView = () => {
    const { navigateTo } = useAppActions();
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();
    const goToSuccessfulPage = useCallback(() => {
        appInsights.trackEvent({ name: "IIDActivationSuccess" });
        navigateTo("/activate/result/success");
    }, [navigateTo, appInsights]);
    const goToSecondConfirm = useCallback(() => {
        appInsights.trackEvent({ name: "IIDActivationFailed" });
        navigateTo("/activate/result/secondConfirm");
    }, [navigateTo, appInsights]);
    const imageStyles = {
        root: {
            marginBottom: 50,
            paddingTop: 20,
            height: 217,
        },
        image: {
            height: 217,
        }
    };
    const yesButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            borderRadius: 8,
        }
    }
    const noButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            background: "none",
            borderRadius: 8,
            borderColor: "#0072c9",
            color: "#0072c9",
        }
    }
    return (
        <Stack>
            <ProductActivationHeader>
                {t("productActivation.title", "Microsoft Product Activation")}
            </ProductActivationHeader>
            <Text variant="large">
                {t("productActivationResultConfirmation.content", "Were you successful activating your product?")}
            </Text>
            <Image src={lockAndPhoneIcon} imageFit={ImageFit.center} styles={imageStyles} alt="Locked Phone Icon." />
            <PrimaryButton
                text={t("common.yes", "Yes")}
                onClick={goToSuccessfulPage}
                styles={yesButtonStyles}
            />
            <Button
                text={t("common.no", "No")}
                onClick={goToSecondConfirm}
                styles={noButtonStyles}
            />
        </Stack>
    );
}