import { Link, Image, ImageFit, IStyle, IStyleFunctionOrObject, ILinkStyleProps, ILinkStyles } from '@fluentui/react';
import arrowSvg from './arrow.svg'


interface ExternalLinkProps {
    url: string,
    styles: IStyleFunctionOrObject<ILinkStyleProps, ILinkStyles>
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ url, styles, children }) => {
    const iconStyles = {
        root: {
            display: "inline-block",
            height: 10,
            width: 10,
            marginRight: 2,
        },
        image: {
            height: 10,
        }
    };
    return (
        <Link href={url} target="_blank" styles={styles}>
            <Image src={arrowSvg} imageFit={ImageFit.center} alt="Link." styles={iconStyles} />
            <span>
                {children}
            </span>
        </Link>
    );
}