import { Stack, Text, Image, Link, TextField, Dialog, DialogType } from '@fluentui/react';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppActions, useAppState } from '../../states';
import { useApiRequest, validateIID } from '../../data';
import { HintButton, NavigateWithUrlId, SubmitButton, CaptchaArea } from '../../components';

import iidIllustration from '@src/resources/iid_illustration.png'

import { ProductActivationHeader } from './productActivationHeader';

const MaxActivationAttempts = 3;

interface IdInputFormState {
    segments: string[],
    setSegment: (number, string) => void,
    focusedSegment: number,
    focusCounter: number,
    inputsEnabledTill: number,
    isSubmitDisabled: boolean,
}


const useIdInputFormData = (numDigits: number): IdInputFormState => {
    const [segments, setSegments] = useState(Array(9).fill(''));
    const [focusedSegment, setFocusedSegment] = useState(0);
    const [inputsEnabledTill, setInputsEnabledTill] = useState(1);
    const [focusCounter, setFocusCounter] = useState(0);
    const setSegment = useCallback((i, value) => {
        if (i === 0 && value.indexOf("-") !== -1) {
            // Special case to paste the whole IID with dash
            value = value.split("-").join("");
        }
        if (i === 0 && value.indexOf(" ") !== -1) {
            // Special case to paste the whole IID with space
            value = value.split(" ").join("");
        }
        if (!/^\d+$/.test(value) && value.length > 0) {
            // Not digits
            return;
        }
        if (i === 0 && value.length > numDigits * 8 && value.length <= numDigits * 9) {
            // Special case to paste the whole IID
            setSegments(value.match(new RegExp(`(.{1,${numDigits}})`, "g")));
            setFocusedSegment(8);
            setFocusCounter(focusCounter + 1);
            setInputsEnabledTill(10);
        }
        if (value.length > numDigits) {
            return;
        }
        setSegments(Object.assign([...segments], { [i]: value }));
        if (value.length === numDigits) {
            for (let next = i + 1; next < 9; next++) {
                if (segments[next].length === numDigits) {
                    continue;
                }
                setFocusedSegment(next);
                setFocusCounter(focusCounter + 1);
                setInputsEnabledTill(Math.max(inputsEnabledTill, next + 1));
                return;
            }
        }
    }, [numDigits, segments, focusCounter, inputsEnabledTill]);

    return {
        segments,
        setSegment,
        focusedSegment,
        focusCounter,
        inputsEnabledTill,
        isSubmitDisabled: !segments.every((s, i) => {
            if (!s) {
                return false;
            }
            if (i < 8) {
                return s && s.length === numDigits;
            } else {
                return s.length >= 2;
            }
        }),
    };
}


interface ProductInstallationIdSegmentProps {
    value: string,
    placeholder: string,
    index: number,
    disabled: boolean,
    setSegment: (number, string) => void,
    inputRef: React.MutableRefObject<any>
}


const ProductInstallationIdSegment = ({ value, placeholder, index, disabled, setSegment, inputRef }: ProductInstallationIdSegmentProps) => {
    const onChange = useCallback((event, newValue) => setSegment(index, newValue), [setSegment, index]);

    const inputStyles = {
        root: {
            width: "32%",
            paddingLeft: 14,
            paddingRight: 14,
            marginTop: 7,
            marginBottom: 8,
        },
        fieldGroup: {
            height: 55,
            border: `1px solid #C8C8C8`,
        },
        field: {
            fontSize: 17,
        },
        subComponentStyles: {
            label: {
                root: {
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: '400',
                    paddingBottom: 2
                }
            }
        }
    }

    return <TextField
        label={String(index + 1)}
        type="text"
        inputMode="decimal"
        componentRef={inputRef}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        styles={inputStyles}
        autoComplete="none"
    />;
}


interface ProductInstallationIdInputBoxProps {
    segments: string[],
    setSegment: (number, string) => void,
    placeholder: string,
    inputsEnabledTill: number,
    disabled: boolean,
    focusedSegment: number,
    focusCounter: number,
}


const ProductInstallationIdInputBox = ({ segments, setSegment, placeholder, inputsEnabledTill, disabled, focusedSegment, focusCounter }: ProductInstallationIdInputBoxProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRefs = useMemo(() => Array(9).fill(null).map(_ => React.createRef<HTMLInputElement>()), []);
    useEffect(() => {
        const input = inputRefs[focusedSegment].current;
        if (input !== null) {
            input.focus();
        }
    }, [focusedSegment, focusCounter, inputRefs]);

    const containerStyles = {
        root: {
            marginLeft: -14,
            marginRight: -14,
            marginBottom: 25,
        }
    }

    return (
        <Stack horizontal wrap horizontalAlign="space-around" styles={containerStyles}>
            {inputRefs.map((ref, i) =>
                <ProductInstallationIdSegment
                    inputRef={ref}
                    value={segments[i]}
                    placeholder={placeholder}
                    disabled={disabled || i >= inputsEnabledTill}
                    index={i}
                    key={i}
                    setSegment={setSegment}
                />)}
        </Stack>
    );
}

export const ProductInstallationIdFormView = () => {
    const { t } = useTranslation();
    const { featureOptions, urlId, selectedProduct, validationResponse, activationAttempts } = useAppState();
    const { setValidationResponse, navigateTo, reloadUrlInfo, setActivationAttempts } = useAppActions();
    const { segments, setSegment, focusedSegment, focusCounter, inputsEnabledTill, isSubmitDisabled } = useIdInputFormData(selectedProduct.numberOfDigits);
    const [suppressCheckSumError, setSuppressCheckSumError] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const validateIIDApi = useApiRequest(validateIID, true);

    const [captchaInput, setCaptchaInput] = useState('');
    const [captchaImage, setCaptchaImage] = useState(null);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const isCaptchaEnabled = featureOptions.isCaptchaEnabled || false;

    useEffect(() => {
        if (validateIIDApi.data !== null) {
            setValidationResponse(validateIIDApi.data);
            if (validateIIDApi.data.validChecksum) {
                // Only record attempt if it's valid checksum
                setActivationAttempts(activationAttempts - 1);
            }
        }
    }, [setValidationResponse, validateIIDApi.data, activationAttempts, setActivationAttempts]);

    // Show captcha when IID is entered for the first time
    useEffect(() => {
        if (!isSubmitDisabled && captchaImage === null) {
            setShowCaptcha(true);
        }
    }, [captchaImage, isSubmitDisabled])

    const gotoHelpPage = useCallback(() => navigateTo("selfhelp"), [navigateTo]);

    const handleValidateIIDResponse = useCallback((response: any) => {
        let status = response.status;
        // if captcha succeeds, data will not contain "captcha". It will return the validateIID response.
        // When we enable captcha, remove the 'response.data === "captcha"' check.
        let isCaptchaError = isCaptchaEnabled && captchaImage != null && response.data === "captcha";

        const disableCaptcha = () => {
            // Disable captcha for this instance. Refreshing will enable it again.
            setCaptchaImage(undefined);
            setShowCaptcha(false);
        };

        const handleCaptchaError = (errorMessage: string) => {
            setCaptchaInput('');
            setCaptchaError(errorMessage);
            setActivationAttempts(activationAttempts - 1);
            setShowCaptchaError(true);
        };

        if (isCaptchaError) {
            setShowCaptchaError(false);
            switch (status) {
                case 400:
                    handleCaptchaError("captchaArea.wrongAnswer");
                    break;
                case 404:
                    handleCaptchaError("captchaArea.challengeExpired");
                    break;
                case 500:
                    disableCaptcha();
                    break;
                case 403:
                    navigateTo("/error/expired");
                    break;
                default:
                    // validate iid failed behavior
                    if (status < 500) {
                        setCaptchaInput('');
                        setCaptchaImage(null);
                        setSuppressCheckSumError(false);
                        reloadUrlInfo();
                    }
                    break;
            }
        } else {
            // captcha successful, OR disabled
            // if we get a 4xx error, we should show the checksum error.
            if (response.status < 500) {
                disableCaptcha();
                setSuppressCheckSumError(false);
                reloadUrlInfo();
            }
        }
    }, [isCaptchaEnabled, captchaImage, setActivationAttempts, activationAttempts, reloadUrlInfo, navigateTo]);

    // call the api to validate the IID.
    const onFormSubmit = useCallback(() => {
        setActivationAttempts(activationAttempts + 1);
        validateIIDApi.call(urlId, { "IID": segments.join("") })
            .then((response) => {
                handleValidateIIDResponse(response);
            }).then(() => setShowDialog(validateIIDApi.showDialog));
    }, [setActivationAttempts, activationAttempts, validateIIDApi, urlId, segments, handleValidateIIDResponse]);

    const isChecksumFailed = validationResponse && !validationResponse.validChecksum;
    const showChecksumError = isChecksumFailed && !suppressCheckSumError;
    const submitButtonDisabled =
        isSubmitDisabled ||
        activationAttempts >= MaxActivationAttempts ||
        (isCaptchaEnabled && showCaptcha && captchaInput === "");

    if (validationResponse && !isChecksumFailed) {
        if (validationResponse.activationSuccessful) {
            return <NavigateWithUrlId to="/activate/result" />;
        } else if (validationResponse.isEligibleForManualOverride) {
            return <NavigateWithUrlId to="/activate/manualOverride" />;
        } else {
            return <NavigateWithUrlId to="/error/activationError" />;
        }
    }

    const promptStyles = {
        root: {
            fontWeight: "400",
            fontSize: 16,
            marginLeft: 3,
            height: 45,
            boxSizing: "border-box"
        }
    };
    const errorPromptStyles = {
        root: {
            fontWeight: "400",
            fontSize: 16,
            marginLeft: 3,
            height: 65,
            boxSizing: "border-box"
        }
    };
    const helpLinkStyles = {
        root: {
            fontSize: 17,
            textAlign: "center",
            textDecoration: "underline",
            color: "#0072c9"
        }
    };
    const iidIllustrationStyles = {
        root: {
            marginTop: 5,
            width: "100%",
        },
        image: {
            width: "100%"
        }
    };
    const modalStyles = { main: { maxWidth: 450 } }


    return (
        <Fragment>
            <ProductActivationHeader hasError={showChecksumError}>
                {t("productActivationInstallationIdForm.title", "The Installation ID is displayed in your product's activation window")}
            </ProductActivationHeader>
            {
                showChecksumError ?
                    <Text styles={errorPromptStyles}>
                        {t(
                            "productActivationInstallationIdForm.errorPrompt",
                            "Please check each group carefully to ensure the Installation ID has been entered correctly. This ID number is displayed in the activation window.")
                        }
                    </Text>
                    :
                    <Text styles={promptStyles}>
                        {t(
                            "productActivationInstallationIdForm.content",
                            "Enter each group of your Installation ID in the corresponding section below")
                        }
                        <HintButton title={t("productActivationInstallationIdForm.hintTitle", "Installation ID")}>
                            {t(
                                "productActivationInstallationIdForm.hintContent",
                                "The Installation ID is a multi group number displayed in the Activation Window on your device. Each group of the Installation ID will have either 6 or 7 digits.")
                            }

                            <Image src={iidIllustration} styles={iidIllustrationStyles} alt="Location of Installation ID in the device Activation Window." />
                        </HintButton>
                    </Text>
            }
            <Dialog
                hidden={!showDialog}
                onDismiss={() => setShowDialog(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("productActivationInstallationIdForm.dialogErrorTitle", "Please try again..."),
                    subText: t("productActivationInstallationIdForm.dialogErrorContent", "Your request timed out, but you may try again by reselecting \"Submit\"."),
                }}
                modalProps={{
                    isBlocking: true,
                    styles: modalStyles,
                }}
            />
            <ProductInstallationIdInputBox
                segments={segments}
                placeholder={''}
                setSegment={setSegment}
                inputsEnabledTill={inputsEnabledTill}
                disabled={validateIIDApi.isLoading}
                focusedSegment={focusedSegment}
                focusCounter={focusCounter}
            />
            {isCaptchaEnabled && showCaptcha &&
                <CaptchaArea
                    captchaInput={captchaInput}
                    setCaptchaInput={setCaptchaInput}
                    captchaImage={captchaImage}
                    setCaptchaImage={setCaptchaImage}
                    showCaptcha={showCaptcha}
                    setShowCaptcha={setShowCaptcha}
                    captchaError={captchaError}
                    showCaptchaError={showCaptchaError}
                    setShowCaptchaError={setShowCaptchaError}
                />}
            <SubmitButton
                text={t("common.formSubmit", "Submit")}
                onSubmit={onFormSubmit}
                isLoading={validateIIDApi.isLoading}
                disabled={submitButtonDisabled}
            />
            <div style={{ height: 8 }} />
            <Link styles={helpLinkStyles} onClick={gotoHelpPage}>
                {t("productActivationInstallationIdForm.helpMe", "Help me find my Installation ID")}
            </Link>
        </Fragment>
    );
}