import { useCallback } from 'react';
import { useParams } from "react-router-dom";
import { Stack, Text, Image} from '@fluentui/react';
import { SelectionList } from "components";
import { useTranslation } from 'react-i18next';

import { unspecifiedProductNameSuffix } from '@src/constants'
import { updateSelectedProducts, useApiRequest } from '@src/data';
import { useAppActions, useAppState } from '@src/states';

import iidIllustration from '@src/resources/iid_illustration.png';


const useUnspecifiedProductSelectedCallback = () => {
    const { urlId } = useAppState();
    const { productGroup, productType, productName } = useParams();
    const { setSelectedProduct, navigateTo } = useAppActions();
    const updateSelectedProductsAPI = useApiRequest(updateSelectedProducts, true);

    return useCallback((numberOfDigits: string) => {
        setSelectedProduct({
            "productType": productType,
            "productGroup": productGroup,
            "productName": productName ?? unspecifiedProductNameSuffix,
            "productLaunchOrder": 0,
            "numberOfDigits": parseInt(numberOfDigits)
        });
        updateSelectedProductsAPI.call(
            urlId,
            {
                productName: productName ?? unspecifiedProductNameSuffix,
                productType: productType,
                productGroup: productGroup,
                numberOfDigits: parseInt(numberOfDigits)
            }
        );
        navigateTo(`/activate`);
    }, [navigateTo, productGroup, productName, productType, setSelectedProduct, updateSelectedProductsAPI, urlId]);
}


const useUnspecifiedProductHelpCallback = () => {
    const { urlId } = useAppState();
    const { productGroup, productType, productName } = useParams();
    const { setSelectedProduct, navigateTo } = useAppActions();

    return useCallback(() => {
        setSelectedProduct({
            "productType": productType,
            "productGroup": productGroup,
            "productName": productName ?? unspecifiedProductNameSuffix,
            "productLaunchOrder": 0,
            "numberOfDigits": 0
        });
        updateSelectedProducts(
            urlId,
            {
                productName: productName ?? unspecifiedProductNameSuffix,
                productType: productType,
                productGroup: productGroup,
                numberOfDigits: 0
            }
        );
        navigateTo(`/selfhelp`);
    }, [navigateTo, productGroup, productName, productType, setSelectedProduct, urlId]);
}


export const UnspecifiedProductGuideView = () => {
    const { t } = useTranslation();
    const onNumberOfDigitsSelected = useUnspecifiedProductSelectedCallback();
    const gotoSelfHelp = useUnspecifiedProductHelpCallback();
    const headerStyles = {
        root: {
            fontSize: 16,
            fontWeight: "600",
            textAlign: "center",
            color: "#000000",
            marginTop: 16,
            marginBottom: 22,
            width: "100%",
            display: "inline-block"
        }
    };
    const contentStyles = {
        root: {
            fontSize: 16,
            fontWeight: "600",
            color: "#000000",
            marginTop: 12,
            marginLeft: 14,
            marginRight: 14,
            marginBottom: 12,
            display: "inline-block"
        }
    };
    const promptContainerStyles = {
        root: {
            fontSize: 16,
            backgroundColor: "white",
            paddingTop: 15,
            paddingBottom: 24,
            marginBottom: 2,
            marginLeft: 12,
            marginRight: 12,
            borderRadius: 8,
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
        }
    };
    const imageStyles = {
        root: {
            marginTop: -3,
            width: "100%",
        },
        image: {
            width: "100%"
        }
    };

    const digitsOptions = [
        { id: "6", name: t("unspecifiedProductGuide.6digit", "6 Digits") },
        { id: "7", name: t("unspecifiedProductGuide.7digit", "7 Digits") },
    ];
    const helpOption = [
        { name: t("unspecifiedProductGuide.helpFindIIDButton", "Help Me Find My Installation ID") },
    ];

    return (
        <div>
            <Text as="h1" styles={headerStyles}>
                {t("unspecifiedProductGuide.title", "Do you see an Installation ID number?")}
            </Text>
            <Stack.Item styles={promptContainerStyles}>
                <ul>
                    <li>{t("unspecifiedProductGuide.findDigitGuide1", "The multi group number is displayed in the Activation Window on your device.")}</li>
                    <li><b>{t("unspecifiedProductGuide.findDigitGuide2", "Each group should have 6 to 7 digits.")}</b></li>
                </ul>
                <Image src={iidIllustration} styles={imageStyles} alt="Location of Installation ID in the device Activation Window." />
            </Stack.Item>
            <Text styles={contentStyles}>
                {t("unspecifiedProductGuide.find6or7digit", "Does your Installation ID have 6 or 7 digits in each block?")}"
            </Text>
            <SelectionList items={digitsOptions} onSelect={onNumberOfDigitsSelected} />
            <Text styles={contentStyles}>
                {t("unspecifiedProductGuide.needHelpFindIID", "If you need help locating your Installation ID, or do not see one on the Activation window:")}
            </Text>
            <SelectionList items={helpOption} onSelect={gotoSelfHelp} />
        </div>
    );
}