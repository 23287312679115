import { useTranslation } from 'react-i18next';
import { ExternalLink } from "components";


export const PrivacyLink = () => {
    const { t } = useTranslation();
    const privacyLinkStyles = {
        root: {
            fontSize: 14,
            lineHeight: 16,
            color: "black",
            display: "block",
            marginTop: 10,
            marginBottom: 10,
        }
    };
    
    return (
        <ExternalLink url={t("common.privacyStatementLink", "https://go.microsoft.com/fwlink/?LinkId=521839")} styles={privacyLinkStyles}>
            {t("common.privacyStatementLinkText", "Privacy statement")}
        </ExternalLink >
    );
}