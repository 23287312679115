import { useCallback } from "react";
import { Stack, Text, Image, ImageFit, Link, IStackTokens, Button } from "@fluentui/react";
import { Trans, useTranslation } from "react-i18next";
import barricadeSVG from '@src/resources/barricade.svg';
import hourglassPNG from './hourglass.png';
import trafficConePNG from './traffic_cone.png';
import { useAppActions, useAppState } from "@src/states";
import { offerWebCallback, recordHasHandledCallbackServerError, useApiRequest, recordCallbackErrorReached } from "@src/data";
import { NavigateWithUrlId, SubmitButton } from "@src/components";
import { Outlet, Route } from "react-router-dom";

interface ContinueButtonProps {
    icon?: string,
    text: string,
    iconHeight?: number,
    onClick: () => void
}

const ContinueButton = (props: ContinueButtonProps) => {
    const buttonStyles = {
        root: {
            isButton: true,
            width: "345px",
            height: "52px",
            radius: "8px",
            color: "#2B88D8",
            borderColor: "white",
            border: "1px solid",
            borderRadius: "8px",
            backgroundColor: "#0078D4",
            textAlign: "center",
            marginBottom: "12px",
            marginTop: "10px!important"
        }
    }
    const buttonTextStyles = {
        root: {
            font: "Roboto",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "18px",
            textAlign: "center",
            width: "319px",
            height: "18px",
            color: "white"
        }
    }
    let content = (
        <Text styles={buttonTextStyles}>
            {props.text}
        </Text>
    );
    if (props.icon !== undefined) {
        const iconStyles = {
            root: {
                height: props.iconHeight,
                width: "100%"
            },
            image: {
                height: "100%"
            }
        };
        content = (
            <Image src={props.icon} imageFit={ImageFit.center} alt={props.text} styles={iconStyles} />
        );
    }

    return (
        <Link onClick={props.onClick} styles={buttonStyles}>
            {content}
        </Link>
    );
}

interface EndButtonProps {
    icon?: string,
    text: string,
    iconHeight?: number,
    disabled?: boolean,
    onClick: () => void
}

const EndButton = (props: EndButtonProps) => {
    const buttonStyles = {
        root: {
            isButton: true,
            width: "345px",
            height: "52px",
            radius: "8px",
            color: "#267ac2",
            borderColor: "#267ac2",
            border: "1px solid",
            borderRadius: "8px",
            backgroundColor: "white",
            textAlign: "center"
        }
    }
    const buttonTextStyles = {
        root: {
            font: "SF Pro Text",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "20px",
            letter: "-0.24px",
            textAlign: "center",
            color: "#267ac2",
        }
    }
    let content = (
        <Text styles={buttonTextStyles}>
            {props.text}
        </Text>
    );
    if (props.icon !== undefined) {
        const iconStyles = {
            root: {
                height: props.iconHeight,
                width: "100%"
            },
            image: {
                height: "100%"
            }
        };
        content = (
            <Image src={props.icon} imageFit={ImageFit.center} alt={props.text} styles={iconStyles} />
        );
    }

    return (
        <Link onClick={props.onClick} styles={buttonStyles}>
            {content}
        </Link>
    );
}

export const ContactLandingPage = () => {
    const { t } = useTranslation();
    const { urlId, hasHandledCallbackServerError } = useAppState();
    const { navigateTo, setCallbackEstimatedWaitMinutes, setCallbackErrorReached, setHasHandledCallbackServerError} = useAppActions();
    const offerWebCallbackApi = useApiRequest(offerWebCallback, true);
    const gotoCancelPage = useCallback(() => navigateTo("/rate-us/cancel"), [navigateTo]);

    const onConnectMeClicked = useCallback(() => {
        offerWebCallbackApi.call(urlId);
    }, [offerWebCallbackApi, urlId]);

    if (offerWebCallbackApi.data !== null) {
        setCallbackEstimatedWaitMinutes(offerWebCallbackApi.data.estimatedWaitMinutes);
        return <NavigateWithUrlId to="callback" replace/>;
    }

    if (offerWebCallbackApi.error !== null ) {
        // if the error is a 500 error, then we want let them try again one time.

       if(offerWebCallbackApi.status >= 500){
            if (!hasHandledCallbackServerError) {
                setHasHandledCallbackServerError(true);
                recordHasHandledCallbackServerError(urlId);
                return <NavigateWithUrlId to="try-again" />;
            }    
       }
        // make sure we rerender here. 
        recordCallbackErrorReached(urlId);
        setCallbackErrorReached(true);
        return <NavigateWithUrlId to="try-again" replace/>;
    }

    const containerStyles = {
        root: {
            paddingLeft: 15,
            paddingRight: 15,
        }
    }
    const headerStyles = {
        root: {
            height: "28px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000",
            marginTop: 26,
            marginBottom: 20,
        }
    };
    const textStyles = {
        root: {
            height: "60px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
            marginBottom: 52,
        }
    };
    const imageStyles = {
        root: {
            height: 156,
            width: "100%",
            marginBottom: 67
        },
        image: {
            height: 156,
        }
    };
    const buttonStyles = {
        root: {
            marginBottom: 12,
            height: 52,
            borderRadius: 8
        }
    };

    return (
        <Stack styles={containerStyles}>
            <Text as="h1" styles={headerStyles}>
                {t("contactSupport.title", "Contact Assisted Support")}
            </Text>
            <Text as="p" styles={textStyles}>
                <Trans i18nKey="contactSupport.content">
                    I can transfer you to a product activation support advocate, but <strong>you'll have to start the activation process again</strong>.
                </Trans>
            </Text>
            <Image src={barricadeSVG} alt="Barricade." aria-label="barricade" className="barridcade" imageFit={ImageFit.center} styles={imageStyles} />
            <SubmitButton
                text={t("contactSupport.connectMe", "Connect me")}
                onSubmit={onConnectMeClicked}
                isLoading={offerWebCallbackApi.isLoading}
            />
            <Button
                text={t("contactSupport.exit", "Exit")}
                onClick={gotoCancelPage}
                disabled={offerWebCallbackApi.isLoading}
                styles={buttonStyles}
            />
        </Stack>
    );
}

export const CallbackPage = () => {
    const { t } = useTranslation();
    const { phoneNumber, callbackEstimatedWaitMinutes } = useAppState();
    const { navigateTo } = useAppActions();
    const gotoFeedbackPage = useCallback(() => navigateTo("/rate-us"), [navigateTo]);

    if (callbackEstimatedWaitMinutes === undefined) {
        return <NavigateWithUrlId to="/contact" />;
    }

    const headerStyles = {
        root: {
            width: "253px",
            height: "28px",
            top: "177px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesOne = {
        root: {
            width: "332px",
            font: "Segoe UI",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "22px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesTwo = {
        root: {
            width: "332px",
            font: "Segoe UI",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "20px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const imageStyles = {
        root: {
            width: "100%"
        },
        image: {
            width: "100%",
            paddingTop: "30px",
            paddingBottom: "30px"
        }
    };
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 30,
        padding: 0
    };

    return (
        <Stack horizontalAlign="center" tokens={verticalGapStackTokens}>
            <Text as="h1" styles={headerStyles}>
                {t("contactSupport.title", "Contact Assisted Support")}
            </Text>
            <Text as="p" styles={textStylesOne}>
                <Trans i18nKey="contactSupport.successMessageOne">
                    We'll call you at this number <strong>{{ phoneNumber }}</strong> saving you time and effort of waiting on hold.
                </Trans>
            </Text>
            <Image src={hourglassPNG} alt="Hourglass." aria-label="hourglass" className="hourglass" imageFit={ImageFit.centerContain} styles={imageStyles} width={135} height={160} />
            <Text as="p" styles={textStylesTwo}>
                <Trans i18nKey="contactSupport.successMessageWaitTime">
                    The estimated wait time for the call back is currently <strong>{{ callbackEstimatedWaitMinutes }} minutes</strong>.
                </Trans>
                <br />
                <br />
                {t("contactSupport.successMessageTwo", "We look forward to speaking with you.")}
            </Text>
            <Stack>
                <EndButton text={t("common.rateUs", "Rate us")} onClick={gotoFeedbackPage} />
            </Stack>
        </Stack>
    );
}

export const TryAgainPage = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoFeedbackPage = useCallback(() => navigateTo("/rate-us"), [navigateTo]);

    const headerStyles = {
        root: {
            width: "253px",
            height: "28px",
            top: "177px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#000000"
        }
    };
    const textStylesOne = {
        root: {
            width: "349px",
            font: "Segoe UI",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "22px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto"
        }
    };
    const textStylesTwo = {
        root: {
            width: "339px",
            height: "42px",
            font: "Segoe UI",
            fontWeight: "400px",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center"
        }
    };
    const imageStyles = {
        root: {
            width: "100%",
        },
        image: {
            width: "100%",
            paddingTop: "30px",
            paddingBottom: "30px",
        }
    };
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 30,
        padding: 0
    };

    return (
        <Stack horizontalAlign="center" tokens={verticalGapStackTokens}>
            <Text as="h1" styles={headerStyles}>
                {t("contactSupport.title", "Contact Assisted Support")}
            </Text>
            <Text as="p" styles={textStylesOne}>
                <Trans i18nKey="contactSupport.failedMessageOne">
                    We are unable to connect you with an agent at this time due to system maintenance. We apologize for the inconvenience.<br />
                    <br />
                    Please try again later.
                </Trans>
            </Text>
            <Image src={trafficConePNG} alt="Traffic Cone." aria-label="trafficcone" className="trafficcone" imageFit={ImageFit.centerContain} styles={imageStyles} width={160} height={143} />
            <Text as="p" styles={textStylesTwo}>
                <Trans i18nKey="contactSupport.failedMessageTwo">
                    Thank you for contacting Microsoft
                </Trans>
            </Text>
            <Stack>
                <EndButton text={t("common.rateUs", "Rate us")} onClick={gotoFeedbackPage} />
            </Stack>
        </Stack>
    );
}

export const ContactSupportPage = () => {
    const { callbackPageEnabled } = useAppState();
    if (!callbackPageEnabled) {
        return <NavigateWithUrlId to="/" />;
    }
    return <Outlet />
}

export const ContactPageRoutes = [
    <Route key="ContactSupportPage" index element={<ContactLandingPage />} />,
    <Route key="CallbackPage" path="callback" element={<CallbackPage />} />,
    <Route key="TryAgainPage" path="try-again" element={<TryAgainPage />} />
];