import { ImageFit, Stack, PrimaryButton, Text, Image, Button } from '@fluentui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppActions } from '@src/states';
import certificateIcon from '@src/resources/certificate.svg';

import { ProductActivationHeader } from './productActivationHeader';


export const ActivationResultSuccessView = () => {
    const { navigateTo } = useAppActions();
    const { t } = useTranslation();
    const gotoRateUsPage = useCallback(() => navigateTo("/rate-us"), [navigateTo]);
    const headerStyles = {
        root: {
            fontSize: 22,
            fontWeight: "600",
            textAlign: "center",
            marginBottom: 38,
        }
    };
    const imageStyles = {
        root: {
            marginBottom: 42,
            height: 194,
        },
        image: {
            height: 194,
        }
    };
    const contentStyles = {
        root: {
            fontSize: 14,
            fontWeight: "400",
            textAlign: "center",
            marginBottom: 27
        }
    };
    const buttonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            background: "none",
            borderRadius: 8,
            borderColor: "#0072c9",
            color: "#0072c9",
        }
    };
    return (
        <Stack>
            <ProductActivationHeader>
                {t("productActivationResultSuccess.header", "Product successfully activated")}
            </ProductActivationHeader>
            <Text styles={headerStyles}>
                {t("productActivationResultSuccess.content", "Great! Your product has been successfully activated")}
            </Text>
            <Image src={certificateIcon} imageFit={ImageFit.center} styles={imageStyles} alt="Success Certificate Icon." />
            <Text styles={contentStyles}>
                {t("productActivationResultSuccess.thankyou", "Thank you for contacting Microsoft, goodbye!")}
            </Text>
            <Button
                text={t("productActivationResultSuccess.rateus", "Rate us")}
                onClick={gotoRateUsPage}
                styles={buttonStyles}
            />
        </Stack>
    );
}