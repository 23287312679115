import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import logoSvg from './logo.svg'
import { Stack, Image, Link } from '@fluentui/react';
import { useAppActions } from '@src/states';
import { Navigate } from 'react-router-dom';


export interface NavBarProps {
    whiteBackground?: boolean;
    buttons?: boolean;
    back?: boolean;
}

export const NavBar: FC<NavBarProps> = ({ whiteBackground = false, buttons = false, back = false }) => {
    const { navigateTo, setValidationResponse } = useAppActions();
    const { t } = useTranslation();
    const goHome = useCallback(() => {
        setValidationResponse(undefined);
        navigateTo(`/select`);
    }, [navigateTo, setValidationResponse]);
    const goHelp = useCallback(() => navigateTo(`/selfhelp`), [navigateTo]);
    const goBack = useCallback(() => navigateTo(-1), [navigateTo]);

    const navBarStyles = {
        root: {
            height: 48,
            background: whiteBackground ? "white" : "none",
            paddingLeft: 14,
            paddingRight: 14,
        }
    };
    const logoStyles = {
        image: {
            margin: 14,
            marginTop: 15,
            height: 20
        }
    };
    const sideSectionStyles = {
        root: {
            width: "30%",
        }
    };
    const linkStyles = {
        root: {
            fontSize: 17,
            isButton: true,
            selectors: {
                ":hover": {
                    textDecoration: "none",
                },
                ":active:hover": {
                    textDecoration: "none",
                }
            }
        }
    };
    return (
        <Stack horizontal horizontalAlign="space-between" styles={navBarStyles}>
            <Stack horizontal horizontalAlign="start" styles={sideSectionStyles }>
                {buttons && (<Link styles={linkStyles} onClick={goHome} data-testid="nav-home-button">{t('common.homePage', "Home")}</Link>)}
            </Stack>
            <Image src={logoSvg} styles={logoStyles} alt="Microsoft Logo." />
            <Stack horizontal horizontalAlign="end" styles={sideSectionStyles}>
                {(buttons && !back) && (<Link styles={linkStyles} onClick={goHelp} data-testid="nav-help-button">{t('common.helpPage', "Help")}</Link>)}
                {(buttons && back) && (<Link styles={linkStyles} onClick={goBack} data-testid="nav-back-button">{t('common.back', "Back")}</Link>)}
            </Stack>
        </Stack>
    );
}