import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const connectToAppinsight = (connectionString: string, urlId: string) => {
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            connectionString,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            correlationHeaderExcludedDomains: ['*.queue.core.windows.net']
        }
    });
    var urlIdTagger = (envelope) => {
        envelope.data.UrlId = urlId;
    }
    appInsights.addTelemetryInitializer(urlIdTagger);
    appInsights.loadAppInsights();
    return { reactPlugin, appInsights };
}