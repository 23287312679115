import { forwardRef, useCallback, useRef,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Stack, Text, Image, ImageFit, List, TextField, noWrap } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { productGroupNames } from '@src/constants';
import keySvg from '@src/resources/key_image.svg';
import windowsIcon from '@src/resources/windows_icon.png';
import officeIcon from '@src/resources/office_icon.png';

import { SelectionButton } from './selectionButton';


export const ProductGroupSelectionView = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const selectWindows = useCallback(() => navigate(productGroupNames.windows), [navigate]);
    const selectOffice = useCallback(() => navigate(productGroupNames.office), [navigate]);
    const selectUnspecified = useCallback(() => navigate(productGroupNames.unspecified), [navigate]);

    const containerStyles = {
        root: {
            paddingLeft: 15,
            paddingRight: 15,
        }
    };
    const headerStyles = {
        root: {
            fontSize: 24,
            fontWeight: "700",
            textAlign: "center",
            marginTop: 3,
            marginBottom: 3
        }
    };
    const keyImageStyles = {
        root: {
            height: 93,
            marginBottom: -12,
            width: "100%"
        },
        image: {
            height: 53,
        }
    };
    const textStyles = {
        root: {
            fontSize: 16,
            textAlign: "center",
            marginBottom: 30,
        }
    };

    return (
        <Stack horizontalAlign="center" styles={containerStyles}>
            <Text as="h1" styles={headerStyles}>
                {t("productSelection.title", "Microsoft Product Activation")}
            </Text>
            <Image src={keySvg} imageFit={ImageFit.centerContain} styles={keyImageStyles} alt="Key Icon." />
            <Text as="h3" styles={textStyles}>
                {t("productSelection.content", "What product are you trying to activate?")}
            </Text>
            <SelectionButton ariaLabel="What product are you trying to activate? Windows" text="Windows" icon={windowsIcon} iconHeight={58} onClick={selectWindows} />
            <SelectionButton text="Microsoft Office" icon={officeIcon} iconHeight={37} onClick={selectOffice} />
            <SelectionButton text={t("productSelection.notListedButton", "I don’t see my product listed")} onClick={selectUnspecified} />
    
        </Stack>
    );
}


