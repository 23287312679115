import { useCallback, useEffect, useState } from "react";
import { Icon, Text, TextField, Spinner } from "@fluentui/react";
import { getCaptcha } from "@src/data";
import { t } from "i18next";
import { useAppActions, useAppState } from "@src/states";

const promptStyles = {
  root: {
    fontWeight: "400",
    fontSize: 16,
    marginLeft: 3,
    height: 45,
    boxSizing: "border-box",
  },
};

const imageContainerStyles = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
};

const imageStyles = {
  width: "100%",
  marginBlock: "5%",
};

const refreshStyles = {
  color: "rgb(0, 120, 212)",
  display: "flex",
  flexDirection: "row-reverse" as "row-reverse",
  alignItems: "right",
  cursor: "pointer",
};

interface CaptchaAreaProps {
  captchaInput: string;
  setCaptchaInput: any;
  captchaImage: string;
  setCaptchaImage: any;
  showCaptcha: boolean;
  setShowCaptcha: any;
  captchaError: string;
  showCaptchaError: boolean;
  setShowCaptchaError: any;
}

export const CaptchaArea = ({
  captchaInput,
  setCaptchaInput,
  captchaImage,
  setCaptchaImage,
  showCaptcha,
  setShowCaptcha,
  captchaError,
  showCaptchaError,
  setShowCaptchaError,
}: CaptchaAreaProps) => {
  const { urlId } = useAppState();
  const { navigateTo } = useAppActions();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (captchaImage === null) {
      generateCaptcha();
    }
  }, [captchaImage]);

  const onChangeCaptchaInput = (event) => {
    setCaptchaInput(event.target.value);
  };

  const generateCaptcha = useCallback(async () => {
    setLoading(true);
    setShowCaptchaError(false);
    setCaptchaInput("");
    getCaptcha(urlId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            setCaptchaImage(response.data);
          }
        } else if (response.status === 403 || response.status === 409) {
          // 403: Max refreshes hit, 409: link is expired
          navigateTo("/error/expired");
        } else {
          // Remove captcha if the request fails
          setCaptchaImage(undefined);
          setShowCaptcha(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [urlId]);

  const renderCaptcha = (): JSX.Element => {
    if (!showCaptcha) {
      return null;
    }
    if (loading) {
      return (
        <div style={{ marginBottom: "5%" }}>
          <Spinner size={3} />
        </div>
      );
    }
    return (
      <div className="CaptchaArea">
        {showCaptchaError ? (
          <Text styles={promptStyles}>
            {t(
              captchaError,
              "Wrong answer. Please check the image carefully and enter the characters you see in the image, or refresh for a new image."
            )}
          </Text>
        ) : (
          <Text styles={promptStyles}>
            {t(
              "captchaArea.content",
              "Help us prove you're not a robot. Enter the characters you see in the image."
            )}
          </Text>
        )}
        <div style={imageContainerStyles}>
          <img src={captchaImage} alt="Captcha" style={imageStyles} />
        </div>
        <div onClick={generateCaptcha} style={refreshStyles}>
          <Icon iconName="Refresh" />
          <span style={{ marginInline: "10px" }}>
            {t("captchaArea.refresh", "Refresh captcha")}
          </span>
        </div>
        <TextField
          styles={{ root: { margin: "5% 0" } }}
          name="Captcha Input"
          data-testid="captchaInput"
          placeholder={t(
            "captchaArea.placeholder",
            "Enter the characters here"
          )}
          value={captchaInput}
          onChange={onChangeCaptchaInput}
        />
      </div>
    );
  };

  return <div>{renderCaptcha()}</div>;
};
