import { useEffect } from 'react';
import { Route, Outlet } from "react-router-dom";

import { NavigateWithUrlId } from "@src/components";
import { getAllProducts, useApiRequest } from '@src/data';
import { useAppState } from '@src/states';

import { WithProductInfo } from './productInfoContext';
import { ProductGroupSelectionView } from './productGroupSelectionView';
import { ProductTypeSelectionView } from './productTypeSelectionView';
import { ProductNameSelectionView } from './productNameSelectionView';
import { UnspecifiedProductGuideView } from './unspecifiedProductGuideView';


export const ProductSelectionPage = () => {
    const { urlId, validationResponse } = useAppState();
    const getAllProductsRequest = useApiRequest(getAllProducts, true);
    useEffect(() => {
        if (validationResponse === undefined) {
            getAllProductsRequest.call(urlId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ urlId, validationResponse]);

    if (validationResponse !== undefined) {
        return <NavigateWithUrlId to="/activate" />
    }

    return (
        <WithProductInfo value={getAllProductsRequest.data}>
            <Outlet />
        </WithProductInfo>
    );
}

export const ProductSelectionPageRoutes = [
    <Route key="ProductGroupSelectionView" index element={<ProductGroupSelectionView />} />,
    <Route key="ProductTypeSelectionView" path=":productGroup/" element={<ProductTypeSelectionView />} />,
    <Route key="ProductNameSelectionView" path=":productGroup/:productType/" element={<ProductNameSelectionView />} />,
    <Route key="UnspecifiedProductGuideViewKnownGroup" path=":productGroup/:productType/:productName" element={<UnspecifiedProductGuideView />} />
];