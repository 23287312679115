import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { retryConfig } from "./retryConfig";

interface ApiRequestHook<Args, ResponseData> {
    isLoading: boolean,
    data: ResponseData | null,
    error: string | null,
    call: (...Args) => Promise<AxiosResponse>,
    showDialog: boolean,
    status: number | null
}

/**
 * Wrapper around axios to make API calls easier
 * @param requestFunc : Function to call
 * @param shouldRetry : Whether or not to retry the request if it fails
 * @returns  : ApiRequestHook
 */
export const useApiRequest = <Args, ResponseData>(
    requestFunc: (...Args) => Promise<AxiosResponse<ResponseData>>,
    shouldRetry: boolean = false,
    retries: number = retryConfig.MAXRETRIES): ApiRequestHook<Args, ResponseData> => {
        
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [status, setStatus] = useState(null);

    const call = async (...args: Args[]) => {
        setIsLoading(true);
        try {
            const response = shouldRetry ? await retryRequest(requestFunc, args) : await requestFunc(...args);
            setData(response.data);
            setStatus(response.status);
            return response;
        } catch (err) {
            setError(err.message);
            if (err.response) {
                setStatus(err.response.status);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const retryRequest = async (
        request: (...Args) => Promise<AxiosResponse<ResponseData>>,
        args: Args[]
    ) => {
        while (retries > 0) {
            try {
                const response = await request(...args);
                // If the status code is less than 500, return the response
                if (response.status < 500) {
                    return response;
                }
                retries--;
            } catch (err) {
                if (err.response && err.response.status < 500) {
                    throw err;
                }
                retries--;
                if (retries === 0)
                    throw err;
            }
        }
    
        setShowDialog(true);
    };
    return {
        isLoading,
        data,
        error,
        call,
        showDialog,
        status
    };
}

export interface ShortUrlInfo {
    experienceContent: ShortUrlInfoExperienceContent
}

export interface ShortUrlInfoExperienceContent {
    language: string,
    productType: string,
    productGroup: string,
    productName: string,
    phoneNumber: string,
    numberOfDigits: number,
    hasSelectedProduct: boolean,
    callbackPageEnabled: boolean,
    selfHelpActivated: boolean,
    validationResponse: string,
    errorType: string,
    callbackEstimatedWaitMinutes: number,
    hasFeedbackSubmitted: boolean,
    checksumCount: number,
    activationAttempts: number,
    hasHandledCallbackServerError: boolean,
    callbackErrorReached: boolean,

}

export const getShortUrlInfo = async (urlId: string, recordUrlHit: boolean = false): Promise<AxiosResponse<ShortUrlInfo>> => {
    return await axios.get(`/api/shorturl/${urlId}?recordUrlHit=${recordUrlHit}`, {
        validateStatus: () => true
    });
}

export interface ProductInfo {
    productType: string,
    productGroup: string,
    productName: string,
    productLaunchOrder: number,
    numberOfDigits: number
}

export const getFeatureOptions = async (urlId: string): Promise<{ [key: string]: boolean; }> => {
    const response: AxiosResponse<{ [key: string]: boolean; }> = await axios.get(`/api/productActivation/${urlId}/featureOptions`);
    return response.data;
}

export const getAllProducts = async (urlId: string): Promise<AxiosResponse<ProductInfo[]>> => {
    return await axios.get(`/api/productActivation/${urlId}/productUIInfo`);
}

interface UpdateSelectedProductRequest {
    productType: string,
    productName: string,
    productGroup: string,
    numberOfDigits: number
}

export const updateSelectedProducts = async (urlId: string, request: UpdateSelectedProductRequest): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/updateSelectedProduct`, request);
}

interface ValidateIIDRequest {
    IID: string,
    CaptchaInput: string,
    installedDevices?: number,
    overrideStatusCode?: string
}

export interface ValidateIIDResponse {
    message: string,
    activationSuccessful: boolean,
    validChecksum: boolean,
    iid: string
    cid?: string,
    reasonCode?: string,
    overrideStatusCode?: string,
    maxInstallCount?: number,
    isEligibleForManualOverride?: boolean,
    isReasonCodeTransferEnabled?: boolean,
    endOfSupportEnabled?: boolean,
    isDifferentiateEmbedEnabled?: boolean,
    productName: string
}

export const validateIID = async (urlId: string, request: ValidateIIDRequest): Promise<AxiosResponse<ValidateIIDResponse>> => {
    return await axios
        .post(`/api/productActivation/${urlId}/validateIID`, request)
        .then((response) => {
            if (response.status === 200) {
                return response;
            }
        })
        .catch((error) => {
            return error.response;
        });
}

export const getCaptcha = async (urlId: string): Promise<AxiosResponse<string>> => {
    return axios
        .get(`/api/productActivation/${urlId}/getCaptcha/`)
        .then((response) => {
            if (response.status === 200) {
                return response;
            }
        })
        .catch((error) => {
            return error.response;
        });
}

export interface OfferWebCallbackResponse {
    estimatedWaitMinutes: number,
}

export const offerWebCallback = async (urlId: string): Promise<AxiosResponse<OfferWebCallbackResponse>> => {
    return await axios.post(`/api/productActivation/${urlId}/offerWebCallback`);
}

export interface SubmitFeedbackRequest {
    box1Value: string,
    box2Value: string,
    textInputValue: string,
}

export const onFeedbackSubmitted = async (urlId: string, data: SubmitFeedbackRequest): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/onFeedbackSubmitted`, data);
}

export const enabledCallbackPage = async (urlId: string): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/setCallbackPageEnabled`);
}

export const recordSelfHelpActivated = async (urlId: string): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/setSelfHelpActivated`);
}

export const recordHasHandledCallbackServerError = async (urlId: string): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/setHasHandledCallbackServerError`);
}

export const recordCallbackErrorReached = async (urlId: string): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/recordCallbackErrorReached`);
}

export const onError = async (urlId: string, errorType: string): Promise<AxiosResponse<void>> => {
    return await axios.post(`/api/productActivation/${urlId}/onError`, {
        "ErrorType": errorType
    });
}