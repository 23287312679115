import { Navigate } from 'react-router-dom';
import { useAppActions, useAppState } from 'states';


export interface NavigateWithUrlIdProps {
    to: string;
    replace?: boolean;
    state?: any;
}

/**
 * Function to prevent default (i.e back navigation).
 * @param to Current url that the user is trying to move from.  
 * @returns No redirect option for related url. 
 */
export const NavigateWithUrlId = ({ to, ...props }: NavigateWithUrlIdProps) => {
    const { urlId } = useAppState();
    const { enableCallbackPage } = useAppActions();

    if (to.startsWith("/contact")) {
        enableCallbackPage();
    }

    if (to[0] == "/") {
        to = `/${urlId}${to}`;
    }

    return <Navigate to={to} {...props} />;
}