import { Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { Route, Outlet } from "react-router";

import { useAppActions, useAppState } from '@src/states';

import { ProductInstallationIdFormView } from './productInstallationIdFormView';
import { ProductActivationManualOverrideFormView } from './productActivationManualOverrideFormView';
import { ProductConfirmationIdSecondConfirmationView, ProductConfirmationIdView } from './productConfirmationIdView';
import { ActivationResultConfirmationView } from './activationResultConfirmationView';
import { ActivationResultSuccessView } from './activationResultSuccessView';
import { ProductConfirmationXPEmbeddedCheckView } from './productConfirmationIdView';


export const ProductActivationPage = () => {
    const { selectedProduct } = useAppState();
    const { navigateTo } = useAppActions();
    const [toRedirect, setToRedirect] = useState(false);

    useEffect(() => {
        if (toRedirect) {
            navigateTo("/select");
            setToRedirect(false);
            return;
        }
        if (selectedProduct === undefined || selectedProduct.numberOfDigits == 0) {
            setToRedirect(true);
        }
    }, [navigateTo, selectedProduct, toRedirect, setToRedirect]);

    if (selectedProduct === undefined) {
        return null;
    }

    const containerStyles = {
        root: {
            paddingLeft: 15,
            paddingRight: 15,
        }
    };

    return (
        <Stack styles={containerStyles}>
            <Outlet />
        </Stack>
    );
}

export const ProductActivationPageRoutes = [
    <Route index element={<ProductInstallationIdFormView />} />,
    <Route path="manualOverride" element={<ProductActivationManualOverrideFormView />} />,
    <Route path="result" element={<ProductConfirmationIdView />} />,
    <Route path="result/confirm" element={<ActivationResultConfirmationView />} />,
    <Route path="result/secondConfirm" element={<ProductConfirmationIdSecondConfirmationView />} />,
    <Route path="result/success" element={<ActivationResultSuccessView />} />,
    <Route path="embeddedCheck" element={<ProductConfirmationXPEmbeddedCheckView />} />,
];