import { Stack, PrimaryButton, Text, Icon, Separator, Button } from '@fluentui/react';
import { Fragment, useCallback, useMemo } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Trans, useTranslation } from 'react-i18next';
import { useAppActions, useAppState } from '@src/states';
import { HintButton } from '@src/components';

import { ProductActivationHeader } from './productActivationHeader';

import { ImageFit, Image, TextField } from '@fluentui/react';
import computersIcon from '@src/resources/computers.svg';


const ProductConfirmationIdContainer = () => {
    const { t } = useTranslation();
    const { validationResponse } = useAppState();
    const cidSegments = useMemo(() => validationResponse.cid.match(new RegExp(`(.{1,6})`, "g")), [validationResponse.cid]);
    const contentContainerStyles = {
        root: {
            backgroundColor: "white",
            borderRadius: 8,
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
            paddingLeft: 17,
            paddingRight: 17,
            paddingTop: 10,
            paddingBottom: 15,
            marginBottom: 15,
        }
    };
    const contentHeaderStyles = {
        root: {
            fontSize: 16,
            fontWeight: "600",
            paddingLeft: -4,
        }
    };
    const cidContainerStyles = {
        root: {
            paddingTop: 15,
            marginBottom: -8
        }
    };
    const cidSegmentStyles = {
        root: {
            width: "20%",
            marginBottom: 12,
        }
    };
    const cidSegmentIndexStyles = {
        root: {
            fontSize: 17,
            fontWeight: "400",
            marginBottom: 7,
        }
    };
    const cidSegmentValueStyles = {
        root: {
            fontSize: 14,
            fontWeight: "400",
        }
    };
    const separatorStyles = {
        root: {
            marginLeft: -17,
            marginRight: -17
        }
    };
    return (
        <Stack styles={contentContainerStyles}>
            <Text styles={contentHeaderStyles}>
                <HintButton title={t("productActivationConfirmationId.hintButton", "Confirmation ID")}>
                    <Trans i18nKey="productActivationConfirmationId.hintContent">
                        The Confirmation ID is different from the Installation ID you previously entered.
                        The Confirmation ID needs to be entered into the activation window on your machine to complete activation of your product.
                    </Trans>
                </HintButton>
                {t("productActivationConfirmationId.hintButton", "Confirmation ID")}
            </Text>
            <Stack horizontal wrap horizontalAlign="start" styles={cidContainerStyles}>
                {
                    cidSegments.map((segment, i) => (
                        <Stack key={segment} horizontalAlign="center" styles={cidSegmentStyles}>
                            <Text styles={cidSegmentIndexStyles}>{(i + 10).toString(36).toUpperCase()}</Text>
                            <Text styles={cidSegmentValueStyles}>{segment}</Text>
                        </Stack>
                    ))
                }
            </Stack>
            <Separator styles={separatorStyles} />
            <Text styles={contentHeaderStyles}>
                <HintButton title={t("productActivationConfirmationId.screenShotHintTitle", "Screenshot")}>
                    {t("productActivationConfirmationId.screenShotHintContent", "We recommend taking a screenshot of this page, or writing down this Confirmation ID for future reference.")}
                </HintButton>
                {t("productActivationConfirmationId.screenShotHintButton", "Take a screenshot")}
            </Text>
        </Stack>
    );
}

export const ProductConfirmationIdView = () => {
    const { t } = useTranslation();
    let { validationResponse } = useAppState();
    const { navigateTo } = useAppActions();

    const onContinueClicked = useCallback(() => {
        navigateTo("/activate/result/confirm");
    }, [navigateTo]);

    if (!validationResponse.cid) {
        return null;
    }

    const contentTextStyle = {
        root: {
            fontSize: 15,
            fontWeight: "400",
            marginBottom: 24
        }
    };
    const iconStyles = {
        root: {
            color: "#6BB700",
            marginLeft: 6,
            position: "relative" as "relative",
            top: 2,
        }
    };
    const submitButtonStyles = {
        root: {
            marginBottom: 10,
            height: 52,
            borderRadius: 8,
        }
    };

    return (
        <Fragment>
            <ProductActivationHeader>
                {t("productActivationConfirmationId.success", "Success!")}
                <Icon iconName="CompletedSolid" styles={iconStyles} />
            </ProductActivationHeader>

            <Text styles={contentTextStyle}>
                <Trans i18nKey="productActivationConfirmationId.content">
                    To complete the activation process, enter this confirmation ID into the activation window on your computer and click 'next' or 'activate'. <b>Save this number for future reference.</b>
                </Trans>
            </Text>
            <ProductConfirmationIdContainer />
            <div style={{ height: 25 }} />
            <PrimaryButton
                text={t("common.continue", "Continue")}
                onClick={onContinueClicked}
                styles={submitButtonStyles}
            />
        </Fragment>
    );
}

export const ProductConfirmationIdSecondConfirmationView = () => {
    const { t } = useTranslation();
    const { validationResponse } = useAppState();
    const { navigateTo
    } = useAppActions();
    const appInsights = useAppInsightsContext();
    const goToSuccessfulPage = useCallback(() => {
        appInsights.trackEvent({ name: "IIDActivationSuccess" });
        navigateTo("/activate/result/success");
    }, [navigateTo, appInsights]);

    const goToDeterminedNextPage = useCallback(() => {
        const hasValidationResponse = !!validationResponse;
        const endOfSupportEnabled = validationResponse.endOfSupportEnabled;
        const isWindowsXP = validationResponse.productName === 'Windows XP';
        const isEmbeddedVersion = validationResponse.isDifferentiateEmbedEnabled;

        appInsights.trackEvent({ name: "IIDActivationFailed" });
        if (hasValidationResponse && !endOfSupportEnabled) {
            navigateTo('/contact')
            return;
        }

        if (hasValidationResponse && !isWindowsXP) {
            navigateTo("/error/noFurtherSupport")
            return;
        }

        if (hasValidationResponse && !isEmbeddedVersion) {
            navigateTo("/error/noFurtherSupport")
            return;
        }
        navigateTo('/activate/embeddedCheck');
    }, [appInsights, validationResponse, navigateTo]);

    if (!validationResponse.cid) {
        return null;
    }

    const promptTextStyles = {
        root: {
            fontSize: 16,
            marginBottom: 16
        }
    };
    const questionTextStyles = {
        root: {
            fontSize: 18,
            marginBottom: 11
        }
    };
    const yesButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            borderRadius: 8,
        }
    };
    const noButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            background: "none",
            borderRadius: 8,
            borderColor: "#0072c9",
            color: "#0072c9",
        }
    };

    return (
        <Fragment>
            <ProductActivationHeader>
                {t("productActivation.title", "Microsoft Product Activation")}
            </ProductActivationHeader>
            <div style={{ marginBottom: -10 }} />
            <Text block styles={promptTextStyles}>
                <Trans i18nKey="productActivationConfirmationId.retryAgain">
                    <b>Let's try again?</b> Enter each group, check each one carefully before hitting 'Next' or 'Activate' on your computer.
                </Trans>
            </Text>
            <ProductConfirmationIdContainer />
            <Text styles={questionTextStyles}>
                {t("productActivationConfirmationId.question", "Were you successful activating your product?")}
            </Text>
            <PrimaryButton
                text={t("common.yes", "Yes")}
                onClick={goToSuccessfulPage}
                styles={yesButtonStyles}
            />
            <Button
                text={t("common.no", "No")}
                onClick={goToDeterminedNextPage}
                styles={noButtonStyles}
            />
        </Fragment>
    );
}

export const ProductConfirmationXPEmbeddedCheckView = () => {
    const { t } = useTranslation();
    const { validationResponse } = useAppState();
    const { navigateTo
    } = useAppActions();

    const goToContactPage = useCallback(() => {
        navigateTo("/contact");
    }, [navigateTo]);

    const goToEndPage = useCallback(() => {
        navigateTo("/error/noFurtherSupport")
    }, [navigateTo]);

    if (!validationResponse.cid) {
        return null;
    }

    const questionTextStyles = {
        root: {
            fontSize: 18,
            marginBottom: 11,
            textAlign: "left",
            marginLeft: 5
        }
    };
    const yesButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            borderRadius: 8,
        }
    };
    const noButtonStyles = {
        root: {
            marginBottom: 11,
            height: 52,
            background: "none",
            borderRadius: 8,
            borderColor: "#0072c9",
            color: "#0072c9",
        }
    };

    const imageStyles = {
        root: {
            height: 124,
            width: 241,
            marginTop: 61,
            marginBottom: 130,
            marginLeft: 67
        },
        image: {
            height: 124,
            width: 241
        }
    };

    return (
        <Fragment>
            <ProductActivationHeader>
                {t("productActivation.title", "Microsoft Product Activation")}
            </ProductActivationHeader>
            <div style={{ marginBottom: -10 }} />
            <Image src={computersIcon} imageFit={ImageFit.center} styles={imageStyles} alt="Computers Icon." />
            <Text styles={questionTextStyles}>
                {t("productConfirmationXPEmbeddedCheckView.question", "Are you using an Embedded version of Windows?")}
            </Text>
            <PrimaryButton
                text={t("common.yes", "Yes")}
                onClick={goToContactPage}
                styles={yesButtonStyles}
            />
            <Button
                text={t("common.no", "No")}
                onClick={goToEndPage}
                styles={noButtonStyles}
            />
        </Fragment>
    );
}