import { Route } from "react-router";
import { useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Stack, Text, Image, ImageFit, Link, IStackTokens, PrimaryButton, Button } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/style-utilities";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { TextField } from '@fluentui/react/lib/TextField';
import { useAppActions, useAppState } from "states";

import heartSVG from '@src/resources/heart.svg';
import { onFeedbackSubmitted, useApiRequest } from "../../data";


interface CancelButtonProps {
    icon?: string,
    text: string,
    iconHeight?: number,
    onClick: () => void
}

const CancelButton = (props: CancelButtonProps) => {
    const buttonStyles = {
        root: {
            isButton: true,
            width: "345px",
            height: "52px",
            radius: "8px",
            color: "#267ac2",
            borderColor: "#267ac2",
            border: "1px solid",
            borderRadius: "8px",
            backgroundColor: "white",
            textAlign: "center"
        }
    }
    const buttonTextStyles = {
        root: {
            font: "SF Pro Text",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "20px",
            letter: "-0.24px",
            textAlign: "center",
            width: "327px",
            height: "43px",
            radius: "8px",
            color: "#267ac2",
        }
    }
    let content = (
        <Text styles={buttonTextStyles}>
            {props.text}
        </Text>
    );
    if (props.icon !== undefined) {
        const iconStyles = {
            root: {
                height: props.iconHeight,
                width: "100%"
            },
            image: {
                height: "100%"
            }
        };
        content = (
            <Image src={props.icon} imageFit={ImageFit.center} alt={props.text} styles={iconStyles} />
        );
    }

    return (
        <Link onClick={props.onClick} styles={buttonStyles}>
            {content}
        </Link>
    );
}

export const FeedbackLandingPage = () => {
    const { t } = useTranslation();
    const { urlId } = useAppState();
    const { navigateTo, setHasFeedbackSubmitted } = useAppActions();

    const [box1Value, setBox1Value] = useState<string>(undefined);
    const [box2Value, setBox2Value] = useState<string>(undefined);
    const [textInputValue, setTextInputValue] = useState("");
    const onBox1Changed = useCallback((_e, option) => setBox1Value(option.key), []);
    const onBox2Changed = useCallback((_e, option) => setBox2Value(option.key), []);
    const onTextInputChanged = useCallback((_e, newValue) => setTextInputValue(newValue || ''), []);

    const onFeedbackSubmittedApi = useApiRequest(onFeedbackSubmitted, true);
    const gotoThankYouPage = useCallback(() => {
        onFeedbackSubmittedApi.call(urlId, { box1Value, box2Value, textInputValue });
        setHasFeedbackSubmitted(true);
        navigateTo("/rate-us/thank-you");
    }, [onFeedbackSubmittedApi, urlId, box1Value, box2Value, textInputValue, setHasFeedbackSubmitted, navigateTo]);
    const gotoCancelPage = useCallback(() => navigateTo("/rate-us/cancel"), [navigateTo]);

    const containerStyles = {
        root: {
            paddingLeft: 15,
            paddingRight: 15
        }
    };
    const headerStyles = {
        root: {
            height: "22px",
            top: "151px",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#212121"
        }
    };
    const textStyles = {
        root: {
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "22px",
            color: "#000000",
            paddingLeft: 15,
        }
    };
    const radioStyles = {
        root: {
            color: "#F8F8F8",
            border: "1px",
            borderColor: "#919191!important"
        },
        label: {
            fontSize: "16px",
            fontWeight: "400"
        }
    };
    const fieldStyles = {
        root: {
            width: "96%",
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "16px",
                    fontWeight: "400",
                }
            }
        }
    };
    const lineStyles = {
        marginTop: "auto",
        height: "1px",
        border: "none",
        backgroundColor: "#EBEBEB"
    };

    const radioButtonClass = mergeStyleSets({
        radioCircle: {
            selectors: {
                ".ms-ChoiceField-field:before": {
                    borderColor: "#919191"
                }
            }
        }
    });
    const buttonSytles = {
        root: {
            marginBottom: 12,
            height: 52,
            borderRadius: 8,
            width: "100%"
        }
    };
    const textFieldClass = mergeStyleSets({
        textBox: {
            selectors: {
                ".ms-TextField-fieldGroup": {
                    border: "none",
                    marginTop: "7px",
                    marginBottom: "12px"
                }
            }
        }
    });

    const yesNoOptions: IChoiceGroupOption[] = [
        { key: "yes", text: t("common.yes", "Yes"), ariaLabel: t("common.yes", "Yes") },
        { key: "no", text: t("common.no", "No"), ariaLabel: t("common.no", "No") }
    ];

    return (
        <Stack horizontalAlign="center" styles={containerStyles}>
            <Text as="h1" styles={headerStyles}>
                {t("feedback.title", "Feedback")}
            </Text>
            <Text as="ol" styles={textStyles}>
                <li style={{ marginBottom: "20px" }}>
                    <ChoiceGroup
                        required
                        name="enjoyment"
                        data-testid="enjoyment"
                        label={t("feedback.questionOne", "I enjoyed using my smartphone to activate my product")}
                        options={yesNoOptions}
                        className={radioButtonClass.radioCircle}
                        styles={radioStyles}
                        selectedKey={box1Value}
                        onChange={onBox1Changed}
                    />
                    <br />
                    <hr style={lineStyles} />
                </li>
                <li>
                    <ChoiceGroup
                        required
                        name="ease"
                        data-testid="ease"
                        label={t("feedback.questionTwo", "The instructions for moving to the mobile web experience were easy to follow")}
                        options={yesNoOptions}
                        className={radioButtonClass.radioCircle}
                        styles={radioStyles}
                        selectedKey={box2Value}
                        onChange={onBox2Changed}
                    />
                    <br />
                    <hr style={lineStyles} />
                </li>
                <li>
                    <TextField
                        name="etc"
                        data-testid="etc"
                        label={t("feedback.questionThree", "Anything else you’d like to share about your activation experience today?")}
                        placeholder={t("feedback.questionThreePlaceHolder", "Enter feedback")}
                        ariaLabel={t("feedback.questionThreePlaceHolder", "Enter feedback")}
                        className={textFieldClass.textBox}
                        styles={fieldStyles}
                        value={textInputValue}
                        onChange={onTextInputChanged}
                    />
                </li>
            </Text>
            <Text as="p" styles={textStyles}>
                {t("feedback.sensitiveData", "Microsoft reviews your feedback to improve our products and services, so please don’t share any personal or confidential data in your comments.")}
            </Text>
            <PrimaryButton
                type="submit"
                text={t("common.send", "Send")}
                onClick={gotoThankYouPage}
                disabled={box1Value === undefined || box2Value === undefined}
                styles={buttonSytles}
            />
            <Button
                text={t("common.cancel", "Cancel")}
                onClick={gotoCancelPage}
                styles={buttonSytles}
            />
        </Stack>
    );
}

export const ThankYouPage = () => {
    const { t } = useTranslation();
    const headerStyles = {
        root: {
            width: "349px",
            height: "113px",
            font: "Segoe UI",
            fontWeight: "700",
            fontSize: "30px",
            lineHeight: "40px",
            textAlign: "center",
            color: "#212121"
        }
    };
    const textStyles = {
        root: {
            width: "338px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "32px",
            color: "#0072c9",
            textAlign: "center",
            textDecoration: "underline",
            paddingTop: "100px"
        }
    };
    const imageStyles = {
        root: {
            width: "100%"
        },
        image: {
            width: "100%"
        }
    };
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 60,
        padding: 0
    };

    return (
        <Stack horizontalAlign="center" tokens={verticalGapStackTokens}>
            <Text as="h1" styles={headerStyles}>
                {t("feedback.thankYouTitle", "Thank you for your feedback!")}
            </Text>
            <Image src={heartSVG} alt="Heart Icon." aria-label="heart" className="heart" imageFit={ImageFit.centerContain} styles={imageStyles} width={163} height={158} />
            <Text as="p" styles={textStyles}>
                {t("feedback.closeWindow", "You may now close this window")}
            </Text>
        </Stack>
    );
}

export const CancelPage = () => {
    const { t } = useTranslation();
    const { navigateTo } = useAppActions();
    const gotoRateUsPage = useCallback(() => navigateTo("/rate-us"), [navigateTo]);

    const headerStyles = {
        root: {
            width: "224px",
            height: "28px",
            font: "Segoe UI",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "28px",
            textAlign: "center",
            color: "#171717"
        }
    };
    const textStylesOne = {
        root: {
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center",
            color: "#212121"
        }
    };
    const textStylesTwo = {
        root: {
            width: "338px",
            font: "Segoe UI",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "32px",
            color: "#0072c9",
            textAlign: "center",
            textDecoration: "underline",
        }
    };
    const imageStyles = {
        root: {
            width: "100%"
        },
        image: {
            width: "100%",
        }
    };
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 60,
        padding: 0
    };

    return (
        <Stack horizontalAlign="center" tokens={verticalGapStackTokens}>
            <Text as="h1" styles={headerStyles}>
                {t("feedback.great", "Great!")}
            </Text>
            <Image src={heartSVG} alt="Heart Icon." aria-label="heart" imageFit={ImageFit.centerContain} styles={imageStyles} width={163} height={158} />
            <Stack>
                <Text as="p" styles={textStylesOne}>
                    {t("feedback.thankYouAndGoodbye", "Thank you for contacting Microsoft, goodbye!")}
                </Text>
                <Text as="p" styles={textStylesTwo}>
                    {t("feedback.closeWindow", "You may now close this window")}
                </Text>
            </Stack>
            <CancelButton text={t("common.rateUs", "Rate us")} onClick={gotoRateUsPage} />
        </Stack>
    );
}

export const FeedbackPage = () => {
    return <Outlet />;
}

export const FeedbackPageRoutes = [
    <Route key="FeedbackPage" index element={<FeedbackLandingPage />} />,
    <Route key="ThankYouPage" path="thank-you" element={<ThankYouPage />} />,
    <Route key="CancelPage" path="cancel" element={<CancelPage />} />
];