import { createContext } from "react";

import { ProductInfo } from "@src/data";
import { useContext } from "react";

const ProductInfoContext = createContext<ProductInfo[] | null>(null);

export const WithProductInfo = ({ value, children }: { value: ProductInfo[] | null, children: React.ReactNode }) => {
    return <ProductInfoContext.Provider value={value}>
        {children}
    </ProductInfoContext.Provider>
};

export const useProductInfo = () => {
    return useContext(ProductInfoContext);
}